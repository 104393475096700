import React, { Component } from "react";


class About extends Component {
  render() {
 
    return (
      <div className="pages">
          <h2>Contact:</h2>

          <p>For business inquiries: contact@aadh.blog</p>
      
      <br></br>
      <br></br>

      </div>
      

    );
  }

  
}

export default About;
