import React, { Component } from "react";


class Blog extends Component {
  render() {
 
    return (
        
      <div className="Blog" >
                 <div className="pages">
          <h3>Best stocks for 2021
          </h3>
          <p>
              $SPOT
              <br></br>
              $KAHOT
              <br></br>
              $GRVY
              <br></br>
              $TWLO
              <br></br>
              $NET
              <br></br>
              $FVRR
            </p>

              
              </div>

          <div className="pages">
          <h3>CloudFlare
          </h3>
              <p>CloudFlare is one of the most interesting companies out there right now. 
                  Their edge cloud network is unparalleled and lends itself to be used as an extremely performant way to run serverless functions. 
                  A company can via CloudFlare's network run their entire website on the edge in a static format, resulting in blazingly fast response times for their users wherever they are located in the world.
              </p></div>
          <div className="pages">

                    <h3>Blog
          </h3>

          <p>First blog post will be about Cloudflare. Note: The "blog" posts on this page are unpublished works in progress.</p>
      
      <br></br>
      <br></br>
      </div>
      </div>
      

    );
  }
  
}

export default Blog;
