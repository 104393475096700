import React, { Component } from 'react';
import { Route, NavLink, HashRouter } from 'react-router-dom';
import Home from './components/Home';
import Content from './components/Content';
import About from './components/About';
import Blog from './components/Blog';
import "./index.css";
import Portfolio from './components/Portfolio';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
toast.configure()
class App extends Component {
  
  render() {
    const notify = () => {
      toast("Deployed to Cloudflares global edge network using workers.",{position:toast.POSITION.BOTTOM_CENTER,
      autoClose:5000})
    }
    return (
      <div className="App">
        <HashRouter>
          <div className="container">
            <ul className="header">
              <li><NavLink exact to="/">Home</NavLink></li>
              <li><NavLink to="/content">Content</NavLink></li>
              <li><NavLink to="/blog">Blog</NavLink></li>
              <li><NavLink to="/portfolio">Portfolio</NavLink></li>
              <li><NavLink to="/about" onClick={notify}>About </NavLink></li>
            </ul>
            <div >
              <Route exact path="/" component={Home}/>
              <Route path="/content" component={Content}/>
              <Route path="/blog" component={Blog}/>
              <Route path="/portfolio" component={Portfolio}/>
              <Route path="/about" component={About}/>
            </div>
          
            <div className ="copyright">
              <p>©2020 - 2020 Andreas Hansen</p>
            </div>
          </div>
          <div>
        <ToastContainer />
      </div>
        </HashRouter>
      </div>
    );
  }
  
}

export default App;
