import React, { Component } from 'react';
import { TwitterTimelineEmbed } from 'react-twitter-embed';
class Home extends Component {
  render() {
    return (
      <div className="pages">
        <a href="https://twitter.com/GrowthStockInv1">
        <h2>Andreas Hansen @GrowthStockInv1</h2>
        </a>
        <p>Welcome to my blog. <br></br><br></br>This whole site is very new, so there isn't much here yet. I apologize for a poor mobile experience. 
        Click on content for links to my Twitter threads, Kaggle kernels, Soundcloud and Github. On the portfolio section I have a pie chart representing
        my portfolio holdings which is embedded code publishing from a private google sheet. In the Blog section I will write future Blog posts once I get 
        around to writing them.  </p>

          
          <TwitterTimelineEmbed
          sourceType="profile"
          screenName="growthstockinv1"
          options={{width: 400, height: 400}}
        />
  
      </div>
      
    );
  }
}

export default Home;
