import React, { Component } from "react";
import { FaGoogle } from "react-icons/fa";

class Portfolio extends Component {
  render() {
    return (
      <div className="pages">
<h2>Portfolio:</h2>
          <p>Current portfolio, always updated, may take a couple seconds to load from google sheets:</p>
          
         <center> <iframe title= "ldl" width="480" height="300" seamless frameborder="0" scrolling="no" src="https://docs.google.com/spreadsheets/d/e/2PACX-1vSjIayCyw_e8r5c_wgxMa4LZRcDVcTmFrXKBH0RA7GHfrv71da7TvftE9DnInQxnBLaiACxpwPctnUr/pubchart?oid=515177951&amp;format=interactive" alt={FaGoogle}></iframe></center>
      <p>YTD gain: 
      156.92%
      <br></br>
      5Y gain: 583%
      </p>
      </div>

    );
  }

  
}

export default Portfolio;
