import React, { Component } from "react";
import { FaTwitter } from 'react-icons/fa';
import { FaKaggle } from 'react-icons/fa';
import { FaGamepad } from 'react-icons/fa';
import { FaSoundcloud } from 'react-icons/fa';
import seir from '../images/seir.PNG'

class Post extends Component {
  render() {
    return (
      <div className="pages">
        
        <h2>Content</h2>
        <h3>My Twitter Threads</h3>
        <h3><FaTwitter /></h3>
        <a  href="https://twitter.com/GrowthStockInv1/status/1336779007984099330" >
        <p>Unity forma</p>
        </a>
        <a href="https://twitter.com/GrowthStockInv1/status/1336662765616984070">

        <p>Why Spotify's aquisition of Megaphone is a big deal</p>
        </a>

        <a  href="https://twitter.com/GrowthStockInv1/status/1331607747054481411">

        <p>Airbnb - Understanding the investment case</p>
        </a>

        <a  href="https://twitter.com/GrowthStockInv1/status/1331271817026220032">

        <p>Roblox - Understanding the investment case</p>
        </a>

        <a href="https://twitter.com/GrowthStockInv1/status/1337060489457754117">

      <p>Why spotify is undervalued 2020.</p>
      </a>
      <h3>My Kaggle Kernels</h3>
      <h3><FaKaggle /></h3>
      <center><img src={seir} alt="Italian Trulli" width="20%"></img></center>


      <a href="https://www.kaggle.com/andyyh/covid-19-in-denmark-and-the-world">

      <p>Simulation of COVID-19 in Denmark and Europe with SEIR-D model (Danish)</p>
      </a>

      <a href="https://www.kaggle.com/andyyh/coronavirus-analysis-and-viz">

<p>Covid-19 visualizations with matplotlib and plot.ly</p>
</a>

<h3>My Games</h3>
<h3><FaGamepad /></h3>

<a href="https://github.com/w32siooo/raptorremake">

<p>Processing remake of the 1984 dos game Raptor</p>
</a>
<center><img src="https://camo.githubusercontent.com/c98bdfeedf9450f14147e587df6e6cdc9dd9e92a2c6a26ab6916df38a883220e/68747470733a2f2f692e696d6775722e636f6d2f386c584f3667782e706e67"alt="Italian Trulli" width="35%"></img>
</center>
<h3>My Music</h3>
<h3><FaSoundcloud /></h3>

<a href="https://soundcloud.com/pastiii">

<p>Soundcloud</p>
</a>
<center><img src="https://i1.sndcdn.com/artworks-000088849544-h15q68-t500x500.jpg"alt="Italian Trulli" width="10%"></img></center>

      </div>

    );
  }
}

export default Post;
